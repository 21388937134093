import { DataTable, Icon, TableFooter, Text } from 'bold-ui'
import { DateTime } from 'components/date/DateTime'
import { ButtonLink } from 'components/route'
import { usePagedTableProps } from 'components/table'
import { TableBox } from 'components/table/TableBox'
import { useAtividadeColetivaTableLazyQuery } from 'graphql/hooks.generated'
import { AtividadeColetivaQueryInput } from 'graphql/types.generated'
import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router'

import { AtividadeColetivaTableItem, tipoAtividadeRecord } from '../model-atividadeColetiva'

interface AtividadeColetivaTableProps {
  filter: AtividadeColetivaQueryInput
  onChangeFilter: (filter: AtividadeColetivaQueryInput) => void
}

export const AtividadeColetivaTable = (props: AtividadeColetivaTableProps) => {
  const { filter, onChangeFilter } = props
  const match = useRouteMatch()
  const [executeQuery, { data, loading }] = useAtividadeColetivaTableLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    executeQuery({ variables: { input: filter } })
  }, [executeQuery, filter])

  const tableProps = usePagedTableProps({
    loading,
    result: data?.atividadesColetivas,
    onChange: onChangeFilter,
  })

  const renderActions = (item: AtividadeColetivaTableItem) => (
    <ButtonLink size='small' skin='ghost' to={`${match.url}/visualizar-reuniao/${item.id}`}>
      <Icon icon='zoomOutline' />
    </ButtonLink>
  )

  /**
   * TODO (Foundation): Ajustar acesso aos campos quando resolver for evoluído em #21428
   */
  return (
    <TableBox>
      <DataTable<AtividadeColetivaTableItem>
        columns={[
          {
            name: 'dataAtividade',
            header: 'Data da atividade',
            render: (item) => <DateTime format='DD/MM/YYYY' value={item.data} />,
          },
          {
            name: 'tipoAtividade',
            header: 'Tipo de atividade',
            render: (item) => <Text>{tipoAtividadeRecord[item.tipoAtividade]}</Text>,
          },
          {
            name: 'numeroParticipantes',
            header: 'Nº de participantes',
            render: (item) => <Text>{item.numeroParticipantes}</Text>,
          },
          {
            name: 'responsavel',
            header: 'Responsável',
            render: (item) => <Text>{item.lotacaoResponsavel.profissional.nome}</Text>,
          },
          {
            name: 'actions',
            align: 'right',
            render: renderActions,
          },
        ]}
        {...tableProps}
      />
      <TableFooter {...tableProps} pageSize={filter.pageParams?.size} />
    </TableBox>
  )
}
