import { HFlow, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'

export function NewsModalBodyLinks() {
  const {
    ACOMPANHAMENTO_CONDICAO_SAUDE_CSV,
    CUIDADO_COMPARTILHADO_ENABLED,
    HISTORICO_CUIDADO_COMPARTILHADO_ENABLED,
    CADASTRO_IMOVEL_ENABLED,
    SAUDE_INDIGENA_ENABLED,
  } = useFlags()
  return (
    <HFlow hSpacing={2}>
      <VFlow vSpacing={0.2}>
        <Text component='li'>Atualização da competência do SIGTAP</Text>
        <Text component='li'>Agendamento entre profissionais</Text>
        <Text component='li'>Acompanhamento do Território</Text>
        <Text component='li'>Atestado Digital</Text>
        {ACOMPANHAMENTO_CONDICAO_SAUDE_CSV && <Text component='li'>Acompanhamento de condições de saúde</Text>}
        <Text component='li'>Busca ativa de vacinação</Text>
        {CUIDADO_COMPARTILHADO_ENABLED && <Text component='li'>Cuidado Compartilhado</Text>}
        <Text component='li'>e-SUS APS Ampliado</Text>
        <Text component='li'>Facilidades no agendamento de consultas de pré-natal</Text>
        <Text component='li'>Gerenciamento de cookies</Text>
        <Text component='li'>Garantia do acesso</Text>
        {HISTORICO_CUIDADO_COMPARTILHADO_ENABLED && <Text component='li'>Histórico do Cuidado Compartilhado</Text>}
        <Text component='li'>Modelos personalizados para relatórios gerenciais</Text>
      </VFlow>
      <VFlow vSpacing={0.2}>
        <Text component='li'>Prescrição de medicamentos</Text>
        <Text component='li'>Prescrição digital</Text>
        <Text component='li'>Relatório gerencial de cuidado compartilhado</Text>
        <Text component='li'>RNDS - Envio de registros</Text>
        <Text component='li'>Unificação de cadastros</Text>
        <Text component='li'>Videochamadas</Text>
        <Text component='li'>Vínculo entre equipes do PEC</Text>
        {CADASTRO_IMOVEL_ENABLED && <Text component='li'> Cadastro de Imóveis e Famílias </Text>}
        <Text component='li'>Videochamada do profissional convidado adaptada para telefone celular</Text>
        <Text component='li'>Videochamada do profissional convidado adaptada para tablet</Text>
        {SAUDE_INDIGENA_ENABLED && <Text component='li'>Saúde indígena</Text>}
      </VFlow>
    </HFlow>
  )
}
