import { Button, Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { Form, FormRenderProps } from 'components/form'
import {
  LotacaoAndEstagioSelectField,
  LotacaoAndEstagioSelectFieldModel,
} from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import React from 'react'
import { metaPath } from 'util/metaPath'
import { useSaudeIndigenaAuthorization } from 'view/cidadao/authorization/useSaudeIndigenaAuthorization'

import { LotacaoResponsavelFormModel } from './model-lotacaoResponsavel'
import { validateEditarLotacaoResponsavel } from './validator-lotacaoResponsavel'

interface EditarLotacaoResponsavelModalProps {
  open: boolean
  municipioId: ID
  onClose(): void
  onResponsavelChange(novoResponsavel: LotacaoAndEstagioSelectFieldModel): void
  isCadastroImovel?: boolean
  isAtividadeColetiva?: boolean
  loadItemsOnOpen?: boolean
}

const meta = metaPath<LotacaoResponsavelFormModel>()

export function EditarLotacaoResponsavelModal(props: EditarLotacaoResponsavelModalProps) {
  const {
    open,
    municipioId,
    onClose,
    onResponsavelChange,
    isCadastroImovel,
    isAtividadeColetiva,
    loadItemsOnOpen,
  } = props

  const { hasTerritorioIndigenaPermission } = useSaudeIndigenaAuthorization()

  const handleSubmit = (values: LotacaoResponsavelFormModel) => {
    onResponsavelChange(values.lotacao)
    onClose()
  }

  const renderForm = (formProps: FormRenderProps<LotacaoResponsavelFormModel>) => (
    <Modal open={open} onClose={onClose} size='small' closeOnBackdropClick={false}>
      <ModalBody>
        <VFlow vSpacing={1}>
          <Heading level={1}>Alterar responsável</Heading>
          <LotacaoAndEstagioSelectField
            label='Profissional'
            name={meta.lotacao}
            required
            municipioId={municipioId}
            canWriteAtendimento={false}
            canWriteRegistroTardio={false}
            canWriteCadastroDomiciliarAndCadastroIndividual={isCadastroImovel && !hasTerritorioIndigenaPermission}
            canWriteCidadaoTerritorioIndigena={isCadastroImovel && hasTerritorioIndigenaPermission}
            canWriteAtividadeColetiva={isAtividadeColetiva}
            loadItemsOnOpen={loadItemsOnOpen}
          />
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button onClick={onClose}>Cancelar</Button>
          <Button kind='primary' onClick={formProps.handleSubmit}>
            Salvar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )

  return (
    <Form<LotacaoResponsavelFormModel>
      render={renderForm}
      validate={validateEditarLotacaoResponsavel}
      onSubmit={handleSubmit}
      suppressNotificationError
    />
  )
}
