/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Checkbox, HFlow, Icon, isEmpty, Tag, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { HLabel } from 'components/HLabel'
import { useFlags } from 'config/useFlagsContext'
import { useHistoricoRetificaAtendimentoMutationMutation } from 'graphql/hooks.generated'
import { OrigemAtendimento, TipoAtendimentoProfissional, TipoRegistroHistoricoClinico } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { Fragment, useMemo, useState } from 'react'
import { AccordionItemButton } from 'react-accessible-accordion'
import Permissions from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'
import { stringGrouper } from 'util/string/string-grouper'
import { OBSERVACAO_DISABLE_HISTORICO_PRINT } from 'view/atendimentos/atendimento-individual/atendimento-observacao/model-atendObservacao'
import { downloadAtendimentoIndividual } from 'view/atendimentos/detail/components/downloadAtendimentoIndividual'
import { downloadAtendimentoVacinacao } from 'view/atendimentos/detail/components/vacinacao/downloadAtendimentoVacinacao'
import { convertCiap, convertCid10, isCodigoNaoInformado } from 'view/atendimentos/detail/utils-cuidadoCompartilhado'
import {
  LabelCondutaCuidadoCompartilhadoDwRecord,
  LabelEvolucaoCuidadoCompartilhadoRecord,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { RetificacaoAtendimentoButton } from 'view/retificacao-atendimento/RetificacaoAtendimentoButton'

import { useHistoricoPrint } from '../../../hooks/useHistoricoPrint'
import {
  CidadaoHistorico,
  HistoricoAtendimentoModel,
  SubtipoAtendimentoTag,
  tipoRegistroHistoricoData,
} from '../../../model/model-historico'
import {
  hasSomeSubmodulesPlano,
  horaOrTurnoByOrigem,
  isFichaAtendimento,
  isHistoricoPrintEnable,
} from '../../../utils/utils-historico'
import HistoricoHeaderEllipsis from '../../HistoricoHeaderEllipsis'
import { useImpressaoEscutaInicialHistorico } from '../detail/escuta-inicial/ImpressaoEscutaInicialHistorico'
import CiapCidLabel from './components/CiapCidLabel'
import ProfissionaisHistoricoHeaderLabel from './components/ProfissionaisHistoricoHeaderLabel'
import { VisualizarDiscussaoCuidadoCompartilhadoAction } from './components/VisualizarDiscussaoCuidadoCompartilhadoAction'
import RemoveAtendimentoConfirmModal from './RemoveAtendimentoConfirmModal'
import RemoveAtendimentoErrorModal from './RemoveAtendimentoErrorModal'
import HistoricoAccordionHeaderTitleTags from './title/HistoricoAccordionHeaderTitleTags'
import HistoricoAccordionHeaderTitleText from './title/HistoricoAccordionHeaderTitleText'
import { HistoricoDadosClinicos } from './title/HistoricoDadosClinicos'

const ATENDIMENTO_NAO_PERMITE_IMPRESSAO = 'Atendimento não permite impressão'
const ATENDIMENTO_NAO_PERMITE_IMPRESSAO_EM_LOTE = 'Este tipo de atendimento só permite impressão individual'

export interface HistoricoAccordionHeaderProps {
  historicoAtendimento: HistoricoAtendimentoModel
  subtipoAtendimento: SubtipoAtendimentoTag
  refetchOnListagem?(): void
  cidadao: CidadaoHistorico
  checked?: boolean
  onSelectToPrint?(checked: boolean, historico: HistoricoAtendimentoModel): void
  historicoPrintProps: ReturnType<typeof useHistoricoPrint>
}

export default function HistoricoAccordionHeader(props: HistoricoAccordionHeaderProps) {
  const {
    historicoAtendimento,
    subtipoAtendimento,
    cidadao,
    refetchOnListagem,
    checked,
    onSelectToPrint,
    historicoPrintProps,
  } = props
  const [removeAtendimento, { loading }] = useHistoricoRetificaAtendimentoMutationMutation()
  const { analytics } = useFirebase()
  const alert = useAlert()
  const theme = useTheme()
  const { HISTORICO_CUIDADO_COMPARTILHADO_ENABLED, RETIFICACAO_ATENDIMENTO_PROCEDIMENTOS_ENABLED } = useFlags()
  const { downloadEscutaInicial } = useImpressaoEscutaInicialHistorico(historicoAtendimento.idAtendRecente)

  const [modalRemoveOpen, setModalRemoveOpen] = useState(false)
  const [modalRemoveErrorOpen, setModalRemoveErrorOpen] = useState(false)

  const isAtendimentoVacinacao =
    historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.VACINACAO ||
    historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.VACINACAO

  const isEscutaInicial =
    historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.ESCUTA_INICIAL ||
    historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.ESCUTA_INICIAL

  const isAtendimentoProcedimentos =
    historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS

  const isCuidadoCompartilhado =
    HISTORICO_CUIDADO_COMPARTILHADO_ENABLED &&
    historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.CUIDADO_COMPARTILHADO

  const {
    data: { profissional },
    checkAuthorization,
  } = useSession()

  const podeExcluirAtendimento =
    historicoAtendimento.profissional?.id === profissional.id &&
    historicoAtendimento.idAtendRecente &&
    !historicoAtendimento.isCancelado

  const hasPermissaoRegistrarAtendimento = checkAuthorization(
    Permissions.visualizarListaDeAtendimento.registrarAtendimento
  )

  const showRetificacaoAtendimentoButton =
    RETIFICACAO_ATENDIMENTO_PROCEDIMENTOS_ENABLED && hasPermissaoRegistrarAtendimento && isAtendimentoProcedimentos

  const hasCondicoesAvaliadas =
    (historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.ATENDIMENTO_DOMICILIAR ||
      historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.AVALIACAO_ELEGIBILIDADE) &&
    !isEmpty(historicoAtendimento.condicoesAvaliadas)
  const hasExamesRealizados = !isEmpty(historicoAtendimento.examesRealizadosZika)
  const horaOuTurnoAtendimento = horaOrTurnoByOrigem(historicoAtendimento)

  const tipoAtendimentoData =
    tipoRegistroHistoricoData[(historicoAtendimento.tipoApresentacao ?? historicoAtendimento.tipoAtendProf).toString()]

  const isAtendimentoPrintEnable = isHistoricoPrintEnable(historicoAtendimento)
  const isFicha = isFichaAtendimento(historicoAtendimento.origemAtendimento)

  const isPrintEscutaInicialDisabled =
    !historicoAtendimento.idAtendRecente || historicoAtendimento.origemAtendimento !== OrigemAtendimento.PEC

  const printTooltipText =
    historicoAtendimento.hasObservacao && !isFicha && !historicoAtendimento.isAtendObsFinalizado
      ? OBSERVACAO_DISABLE_HISTORICO_PRINT
      : !isAtendimentoPrintEnable
      ? isEscutaInicial && !isPrintEscutaInicialDisabled
        ? ATENDIMENTO_NAO_PERMITE_IMPRESSAO_EM_LOTE
        : ATENDIMENTO_NAO_PERMITE_IMPRESSAO
      : 'Imprimir'

  const classes = useMemo(() => createStyles(theme, tipoAtendimentoData.barColor), [theme, tipoAtendimentoData])

  const printHistorico = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    isEscutaInicial
      ? downloadEscutaInicial()
      : isAtendimentoVacinacao
      ? downloadAtendimentoVacinacao({
          atendimentoProfissionalId: historicoAtendimento.idAtendRecente,
          historicoCabecalhoId: historicoAtendimento.idAtendProcessado,
        })
      : downloadAtendimentoIndividual({
          atendimentoProfissionalId: historicoAtendimento.idAtendRecente,
          historicoCabecalhoId: historicoAtendimento.idAtendProcessado,
        })
    analytics.logEvent('click_button_historico_impressao_atendimento')
  }

  const handleChangePrintCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectToPrint(e.target.checked, historicoAtendimento)
  }

  const handleOpenRemoveConfirmModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setModalRemoveOpen(true)
  }

  const handleOnRemoveAtendimento = () => {
    removeAtendimento({ variables: { id: historicoAtendimento.idAtendRecente } })
      .then(() => {
        setModalRemoveOpen(false)
        alert(
          'success',
          'Atendimento de vacinação excluído com sucesso. As alterações estarão disponíveis na RNDS em torno de 15 dias após o envio para o Centralizador Nacional'
        )
        refetchOnListagem()
        historicoPrintProps.remove(historicoAtendimento)
      })
      .catch(() => {
        setModalRemoveOpen(false)
        setModalRemoveErrorOpen(true)
      })
  }

  const hasCondutaCuidadoCompartilhado =
    !isCodigoNaoInformado(historicoAtendimento.cuidadoCompartilhadoEvolucao?.conduta) ||
    !isUndefinedOrNull(historicoAtendimento.conduta)
  const hasReclassificacaoPrioridade =
    !isCodigoNaoInformado(historicoAtendimento.reclassificacaoPrioridade) ||
    !isUndefinedOrNull(historicoAtendimento.cuidadoCompartilhadoEvolucao?.reclassificacaoPrioridade)

  return (
    <Fragment>
      <RemoveAtendimentoConfirmModal
        modalRemoveOpen={modalRemoveOpen}
        setModalRemoveOpen={setModalRemoveOpen}
        handleRemoveAtendimento={handleOnRemoveAtendimento}
        loading={loading}
        cidadao={cidadao}
        historicoAtendimento={historicoAtendimento}
      />
      <RemoveAtendimentoErrorModal
        modalRemoveErrorOpen={modalRemoveErrorOpen}
        setModalRemoveErrorOpen={setModalRemoveErrorOpen}
      />
      <AccordionItemButton css={classes.accordionButton}>
        <div css={classes.barColor} />
        <div css={classes.accordionButtonContent}>
          <HFlow>
            <div
              css={css`
                padding-top: 0.25rem;
              `}
            >
              <Tooltip text={printTooltipText}>
                <div onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
                  {/* TODO (Foundation): Remover condição de disabled para escuta */}
                  <Checkbox
                    checked={checked}
                    onChange={handleChangePrintCheckbox}
                    disabled={!isAtendimentoPrintEnable || isEscutaInicial}
                  />
                </div>
              </Tooltip>
            </div>
            <VFlow vSpacing={0.5}>
              <HFlow
                style={css`
                  display: inline-flex;
                  flex-wrap: wrap;
                `}
                alignItems='center'
                hSpacing={0.5}
              >
                <HistoricoAccordionHeaderTitleText
                  tipoAtendimentoData={tipoAtendimentoData}
                  horaOuTurnoAtendimento={horaOuTurnoAtendimento}
                />
                <HistoricoAccordionHeaderTitleTags
                  historicoAtendimento={historicoAtendimento}
                  subtipoAtendimento={subtipoAtendimento}
                />
              </HFlow>
              <ProfissionaisHistoricoHeaderLabel
                historicoAtendimento={historicoAtendimento}
                isCuidadoCompartilhado={isCuidadoCompartilhado}
              />
              {isCuidadoCompartilhado && (
                <CiapCidLabel
                  ciap={convertCiap(historicoAtendimento)}
                  cid10={convertCid10(historicoAtendimento)}
                  isBold
                />
              )}
              {!!historicoAtendimento?.estagiario && (
                <Text>{`Estagiário: ${
                  historicoAtendimento.estagiario.nome
                } - ${historicoAtendimento.cboEstagio.nome.capitalize()} - ${
                  historicoAtendimento.cboEstagio.cbo2002
                }`}</Text>
              )}

              {hasCondicoesAvaliadas && (
                <HistoricoHeaderEllipsis tooltipText='Mais condições avaliadas'>
                  <HLabel title='Condições avaliadas:'>
                    {historicoAtendimento?.condicoesAvaliadas &&
                      stringGrouper(...historicoAtendimento.condicoesAvaliadas)}
                  </HLabel>
                </HistoricoHeaderEllipsis>
              )}
              {historicoAtendimento.dadosClinicos && (
                <HistoricoDadosClinicos dadosClinicos={historicoAtendimento.dadosClinicos} />
              )}
              {hasSomeSubmodulesPlano(historicoAtendimento) && (
                <HFlow style={classes.planoTagsWrapper} hSpacing={0.5}>
                  {historicoAtendimento.hasPrescricaoMedicamento && <Tag type='normal'>Prescrição de medicamento</Tag>}
                  {historicoAtendimento.hasAlergia && <Tag type='normal'>Alergia</Tag>}
                  {historicoAtendimento.hasAtestado && <Tag type='normal'>Atestado</Tag>}
                  {historicoAtendimento.hasOrientacao && <Tag type='normal'>Orientação</Tag>}
                  {historicoAtendimento.hasSolicitacaoExame && <Tag type='normal'>Solicitação de exame</Tag>}
                  {historicoAtendimento.hasResultadoExame && <Tag type='normal'>Resultado de exame</Tag>}
                  {(historicoAtendimento.hasEncaminhamentoEspecializado || historicoAtendimento.hasEncaminhamento) && (
                    <Tag type='normal'>Encaminhamento</Tag>
                  )}
                  {historicoAtendimento.hasProcedimentoClinico && <Tag type='normal'>Procedimento clínico</Tag>}
                  {historicoAtendimento.hasMarcadorConsumoAlimentar && (
                    <Tag type='normal'>Marcadores de consumo alimentar</Tag>
                  )}
                  {historicoAtendimento.hasCuidadoCompartilhado && <Tag type='normal'>Compatilhamento de cuidado</Tag>}
                </HFlow>
              )}
              {isAtendimentoVacinacao && (
                <HFlow hSpacing={0.5}>
                  {historicoAtendimento.condicoesVacinacao?.isViajante && <Tag type='normal'>Viajante</Tag>}
                  {historicoAtendimento.condicoesVacinacao?.isGestante && <Tag type='normal'>Gestante</Tag>}
                  {historicoAtendimento.condicoesVacinacao?.isPuerpera && <Tag type='normal'>Puérpera</Tag>}
                  {historicoAtendimento.condicoesVacinacao?.isComunicanteHanseniase && (
                    <Tag type='normal'>Comunicante de hanseníase</Tag>
                  )}
                </HFlow>
              )}
              {isCuidadoCompartilhado && (hasCondutaCuidadoCompartilhado || hasReclassificacaoPrioridade) && (
                <HFlow hSpacing={0.5}>
                  {hasCondutaCuidadoCompartilhado && (
                    <Tag type='normal'>
                      {
                        LabelEvolucaoCuidadoCompartilhadoRecord[
                          historicoAtendimento.cuidadoCompartilhadoEvolucao?.conduta ??
                            LabelCondutaCuidadoCompartilhadoDwRecord[historicoAtendimento.conduta]
                        ]
                      }
                    </Tag>
                  )}
                  {hasReclassificacaoPrioridade && <Tag type='normal'>Reclassificação de prioridade</Tag>}
                </HFlow>
              )}
              {hasExamesRealizados && (
                <HistoricoHeaderEllipsis tooltipText='Mais exames avaliados'>
                  <HLabel title='Exames realizados:'>
                    {historicoAtendimento?.examesRealizadosZika &&
                      stringGrouper(...historicoAtendimento.examesRealizadosZika)}
                  </HLabel>
                </HistoricoHeaderEllipsis>
              )}
            </VFlow>
          </HFlow>
          <HFlow>
            {historicoAtendimento.isCancelado && (
              <div css={classes.marcadoParaExclusao}>
                <Icon
                  icon='clockOutline'
                  size={1}
                  style={css`
                    margin-right: 0.5rem;
                  `}
                />
                Marcado para exclusão
              </div>
            )}
            {isAtendimentoVacinacao && podeExcluirAtendimento && (
              <span>
                <Tooltip text={!historicoAtendimento.isCancelado && 'Excluir atendimento'}>
                  <Button size='small' skin='ghost' onClick={handleOpenRemoveConfirmModal}>
                    <Icon icon='trashOutline' size={1.5} />
                  </Button>
                </Tooltip>
              </span>
            )}
            {isEscutaInicial && (
              <span>
                <Tooltip text={isPrintEscutaInicialDisabled ? ATENDIMENTO_NAO_PERMITE_IMPRESSAO : 'Imprimir'}>
                  <Button size='small' skin='ghost' disabled={isPrintEscutaInicialDisabled} onClick={printHistorico}>
                    <Icon icon='printerOutline' size={1.5} />
                  </Button>
                </Tooltip>
              </span>
            )}
            {showRetificacaoAtendimentoButton && (
              <span>
                <RetificacaoAtendimentoButton
                  cidadaoId={cidadao.id}
                  dataAtendimento={historicoAtendimento.dataAtendimento}
                  lotacao={historicoAtendimento}
                />
              </span>
            )}
            <div
              css={css`
                padding-top: 0.25rem;
              `}
            >
              <HFlow>
                {isCuidadoCompartilhado && (
                  <VisualizarDiscussaoCuidadoCompartilhadoAction
                    cuidadoCompartilhadoId={historicoAtendimento.cuidadoCompartilhado?.id}
                    viaHistorico
                  />
                )}
                <Icon name='accordionAngleIcon' icon='angleDown' size={1.5} />
              </HFlow>
            </div>
          </HFlow>
        </div>
      </AccordionItemButton>
    </Fragment>
  )
}

const createStyles = (theme: Theme, barColor: string) => {
  return {
    accordionButton: css`
      display: flex;
      cursor: pointer;
      border-radius: 2px;
      border: 0px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:hover {
        background-color: ${theme.pallete.gray.c90};
      }
      &[aria-expanded='true'] div:first-of-type {
        border-bottom-left-radius: 2.5px;
      }
      &[aria-expanded='true'] {
        border-bottom: 1px solid ${theme.pallete.gray.c80};
      }
      &[aria-expanded='false'] {
        border-bottom: 0px;
      }
      &[aria-expanded='true'] svg[name='accordionAngleIcon'],
      &[aria-selected='true'] svg[name='accordionAngleIcon'] {
        transform: rotate(180deg);
        transition: 0.4s;
      }
      &[aria-expanded='false'] svg[name='accordionAngleIcon'] {
        transform: rotate(0deg);
        transition: 0.4s;
      }
    `,
    marcadoParaExclusao: css`
      color: ${theme.pallete.status.danger.c50};
      display: flex;
      padding-top: 0.5rem;
    `,
    barColor: css`
      width: 0.5rem;
      background-color: ${barColor};
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
    `,
    accordionButtonContent: css`
      justify-content: space-between;
      background-color: ${theme.pallete.surface.background};
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      padding: 0.5rem;
      display: flex;
      width: 100%;
    `,
    planoTagsWrapper: css`
      display: flex;
      flex-wrap: wrap;
    `,
  }
}
