import { LotacaoEditableTableModel } from 'components/form/editable-table/LotacaoEditableTable'
import { LotacaoResponsavelFormModel } from 'components/lotacao-responsavel/model-lotacaoResponsavel'
import { createValidator, ErrorObject, maxLength, range, required } from 'util/validation'

import { LocalAtividadeFieldModel } from './componentes/LocalAtividadeField'
import { AtividadeColetivaFormModel, IS_TIPO_ATIVIDADE_REUNIAO } from './model-atividadeColetiva'

const localAtividadeValidator = createValidator<LocalAtividadeFieldModel>({
  outraLocalidade: [maxLength(250)],
})

export const atividadeColetivaFormValidator = createValidator<AtividadeColetivaFormModel>(
  {
    lotacaoResponsavel: [required],
    dataAtividade: [required],
    turno: [required],
    numeroParticipantes: [required, range(1, 999)],
    localAtividade: localAtividadeValidator,
    tipoAtividade: [required],
    anotacoes: [maxLength(4000)],
  },
  (form: AtividadeColetivaFormModel, errors: ErrorObject<AtividadeColetivaFormModel>) => {
    if (IS_TIPO_ATIVIDADE_REUNIAO[form.tipoAtividade]) {
      validateReuniaoFormFields(form, errors)
    }

    return errors
  }
)

export const validateLotacoes = (
  lotacaoResponsavel: LotacaoResponsavelFormModel,
  lotacoesEnvolvidas: LotacaoEditableTableModel[]
) => {
  const lotacaoResponsavelInLotacoes = lotacoesEnvolvidas?.some(
    (lotacaoEnvolvida) => lotacaoEnvolvida.lotacao.profissional.id === lotacaoResponsavel?.lotacao?.profissional.id
  )

  return lotacaoResponsavelInLotacoes && 'Profissional responsável não pode ser profissional envolvido'
}

const validateReuniaoFormFields = (
  form: AtividadeColetivaFormModel,
  errors: ErrorObject<AtividadeColetivaFormModel>
) => {
  errors.temasReuniao = required(form.temasReuniao)
}
