/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Theme, useTheme, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { RiscoVulnerabilidadeIvcfEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { MetaPath } from 'util/metaPath'

import { AtendimentoIvcfModel, IVCF_RESULTADOS, IVCF_SUBGRUPOS } from '../model-ivcf'
import { IvcfResultadoAccordion } from './IvcfResultadoAccordion'
import { IvcfResultadoLine } from './IvcfResultadoLine'

interface IvcfResultadoViewProps {
  ivcfPath: MetaPath<AtendimentoIvcfModel>
  values: AtendimentoIvcfModel
}

const PONTUACAO_MAXIMA = 40

export function IvcfResultadoView(props: IvcfResultadoViewProps) {
  const {
    ivcfPath,
    values: { resultado, totalPontos, dimensoesAlteradas },
  } = props

  const title = IVCF_RESULTADOS[resultado].longTitle
  const indicatorPosition = (totalPontos * 100) / PONTUACAO_MAXIMA

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, indicatorPosition, resultado), [indicatorPosition, resultado, theme])

  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={4} style={styles.textGray}>
          Resultado
        </Heading>
        <Heading level={3} style={styles.title}>
          <strong>{title}</strong> | {totalPontos} pontos
        </Heading>
      </VFlow>
      <VFlow>
        <VFlow vSpacing={0.5}>
          <HFlow hSpacing={0.25} style={styles.table}>
            <div css={styles.indicator} />
            <IvcfResultadoLine line={RiscoVulnerabilidadeIvcfEnum.BAIXO} />
            <IvcfResultadoLine line={RiscoVulnerabilidadeIvcfEnum.MODERADO} />
            <IvcfResultadoLine line={RiscoVulnerabilidadeIvcfEnum.ALTO} />
          </HFlow>
          <HFlow hSpacing={0} style={styles.subtitle}>
            <Heading level={5} style={styles.subtitleText}>
              0
            </Heading>
            <Heading level={5} style={styles.subtitleText}>
              7
            </Heading>
            <Heading level={5} style={styles.subtitleText}>
              15
            </Heading>
            <Heading level={5} style={styles.subtitleText}>
              40
            </Heading>
          </HFlow>
        </VFlow>
        <HLabel title='Dimensões alteradas:' style={styles.textGray}>
          {dimensoesAlteradas.map((dimensaoAlterada) => IVCF_SUBGRUPOS[dimensaoAlterada].label).join('; ')}.
        </HLabel>
        <IvcfResultadoAccordion ivcfPath={ivcfPath} />
      </VFlow>
    </VFlow>
  )
}

const createStyles = (theme: Theme, indicatorPosition: number, resultado: RiscoVulnerabilidadeIvcfEnum) => ({
  title: css`
    font-weight: normal;
    color: ${IVCF_RESULTADOS[resultado].textColor};
  `,
  textGray: css`
    color: ${theme.pallete.gray.c20};
  `,
  table: css`
    grid-auto-columns: 17% 20% 63%;
    position: relative;
  `,
  indicator: css`
    width: 1.5rem;
    height: 1.5rem;
    background: ${theme.pallete.surface.main};
    position: absolute;
    border-radius: 50%;
    top: 2rem;
    left: calc(${indicatorPosition}% - 0.75rem);
    box-shadow: inset 0 0 0 0.5rem ${IVCF_RESULTADOS[resultado].textColor};
  `,
  subtitle: css`
    grid-gap: 0;
    grid-auto-columns: 17% 20% calc(63% - 0.45rem) 1%;
  `,
  subtitleText: css`
    color: ${theme.pallete.gray.c40};
  `,
})
