import { LotacaoEditableTableModel } from 'components/form/editable-table/LotacaoEditableTable'
import { ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import { LotacaoResponsavelFormModel } from 'components/lotacao-responsavel/model-lotacaoResponsavel'
import {
  AtividadeColetivaTableQuery,
  PraticaSaudeEnum,
  PublicoAlvoEnum,
  TemaReuniaoEnum,
  TemaSaudeEnum,
  TipoAtividadeEnum,
  TurnoEnum,
} from 'graphql/types.generated'
import { Record } from 'immutable'

import { LocalAtividadeFieldModel } from './componentes/LocalAtividadeField'

export type AtividadeColetivaTableItem = AtividadeColetivaTableQuery['atividadesColetivas']['content'][0]

export const IS_TIPO_ATIVIDADE_REUNIAO: Record<TipoAtividadeEnum, boolean> = {
  REUNIAO_DE_EQUIPE: true,
  REUNIAO_COM_OUTRAS_EQUIPES_DE_SAUDE: true,
  REUNIAO_INTERSETORIAL_CONSELHO_LOCAL_DE_SAUDE_CONTROLE_SOCIAL: true,
  EDUCACAO_EM_SAUDE: false,
  ATENDIMENTO_EM_GRUPO: false,
  AVALIACAO_PROCEDIMENTO_COLETIVO: false,
  MOBILIZACAO_SOCIAL: false,
}

export const TIPOS_ATIVIDADE_REUNIAO = Object.values(TipoAtividadeEnum).filter(
  (tipo) => IS_TIPO_ATIVIDADE_REUNIAO[tipo]
)

export const TIPOS_ATIVIDADE_EM_SAUDE = Object.values(TipoAtividadeEnum).filter(
  (tipo) => !IS_TIPO_ATIVIDADE_REUNIAO[tipo]
)

export const TIPOS_ATIVIDADE_EM_SAUDE_EDUCACAO_PSE: TipoAtividadeEnum[] = TIPOS_ATIVIDADE_EM_SAUDE.filter(
  (tipo) => tipo !== TipoAtividadeEnum.ATENDIMENTO_EM_GRUPO
)

export const PRATICAS_SAUDE_EDUCACAO_PSE = [
  PraticaSaudeEnum.ANTROPOMETRIA,
  PraticaSaudeEnum.DESENVOLVIMENTO_DA_LINGUAGEM,
  PraticaSaudeEnum.PRATICAS_CORPORAIS_ATIVIDADE_FISICA,
  PraticaSaudeEnum.SAUDE_AUDITIVA,
  PraticaSaudeEnum.SAUDE_OCULAR,
  PraticaSaudeEnum.OUTRAS,
]

export const PRATICAS_SAUDE_ATENDIMENTO_EM_GRUPO: PraticaSaudeEnum[] = Object.values(PraticaSaudeEnum).filter(
  (pratica) =>
    pratica !== PraticaSaudeEnum.APLICACAO_TOPICA_DE_FLUOR &&
    pratica !== PraticaSaudeEnum.ESCOVACAO_DENTAL_SUPERVISIONADA
)

export const tipoAtividadeRecord: Record<TipoAtividadeEnum, string> = {
  REUNIAO_DE_EQUIPE: 'Reunião de equipe',
  REUNIAO_COM_OUTRAS_EQUIPES_DE_SAUDE: 'Reunião com outras equipes de saúde',
  REUNIAO_INTERSETORIAL_CONSELHO_LOCAL_DE_SAUDE_CONTROLE_SOCIAL:
    'Reunião intersetorial / Conselho local de saúde / Controle social',
  EDUCACAO_EM_SAUDE: 'Educação em saúde',
  ATENDIMENTO_EM_GRUPO: 'Atendimento em grupo',
  AVALIACAO_PROCEDIMENTO_COLETIVO: 'Avaliação / Procedimento coletivo',
  MOBILIZACAO_SOCIAL: 'Mobilização social',
}

export const temaReuniaoRecord: Record<TemaReuniaoEnum, string> = {
  QUESTOES_ADMINISTRATIVAS_FUNCIONAMENTO: 'Questões administrativas / Funcionamento',
  PROCESSOS_DE_TRABALHO: 'Processos de trabalho',
  DIAGNOSTICO_DO_TERRITORIO_MONITORAMENTO_DO_TERRITORIO: 'Diagnóstico do território / Monitoramento do território',
  PLANEJAMENTO_MONITORAMENTO_DAS_ACOES_DA_EQUIPE: 'Planejamento / Monitoramento das ações da equipe',
  DISCUSSAO_DE_CASO_PROJETO_TERAPEUTICO_SINGULAR: 'Discussão de caso / Projeto terapêutico singular',
  EDUCACAO_PERMANENTE: 'Educação permanente',
  OUTROS: 'Outros',
}

export const temaSaudeRecord: Record<TemaSaudeEnum, string> = {
  ACOES_COMBATE_AEDES_AEGYPTI: 'Ações de combate ao Aedes aegypti',
  AGRAVOS_E_DOENCAS_NEGLIGENCIADAS: 'Agravos e doenças negligenciadas',
  ALIMENTACAO_SAUDAVEL: 'Alimentação saudável',
  AUTOCUIDADO_DE_PESSOAS_COM_DOENCAS_CRONICAS: 'Autocuidado de pessoas com doenças crônicas',
  CIDADANIA_E_DIREITOS_HUMANOS: 'Cidadania e direitos humanos',
  PREVENCAO_AO_USO_DE_ALCOOL_TABACO_E_OUTRAS_DROGAS: 'Prevenção ao uso de álcool, tabaco e outras drogas',
  ENVELHECIMENTO_CLIMATERIO_ANDROPAUSA_ETC: 'Envelhecimento (climatério, andropausa, etc)',
  PLANTAS_MEDICINAIS_FITOTERAPIA: 'Plantas medicinais / Fitoterapia',
  PREVENCAO_DA_VIOLENCIA_E_PROMOCAO_DA_CULTURA_DA_PAZ: 'Prevenção da violência e promoção da cultura da paz',
  SAUDE_AMBIENTAL: 'Saúde ambiental',
  SAUDE_BUCAL: 'Saúde bucal',
  SAUDE_DO_TRABALHADOR: 'Saúde do trabalhador',
  SAUDE_MENTAL: 'Saúde mental',
  SAUDE_SEXUAL_E_REPRODUTIVA: 'Saúde sexual e reprodutiva',
  SEMANA_SAUDE_NA_ESCOLA: 'Semana saúde na escola',
  OUTROS: 'Outros',
}

export const praticaSaudeRecord: Record<PraticaSaudeEnum, string> = {
  ANTROPOMETRIA: 'Antropometria',
  APLICACAO_TOPICA_DE_FLUOR: 'Aplicação tópica de flúor',
  DESENVOLVIMENTO_DA_LINGUAGEM: 'Desenvolvimento da linguagem',
  ESCOVACAO_DENTAL_SUPERVISIONADA: 'Escovação dental supervisionada',
  PRATICAS_CORPORAIS_ATIVIDADE_FISICA: 'Práticas corporais e atividade física',
  PROGRAMA_NACIONAL_CONTROLE_TABAGISMO_1: 'PNCT sessão 1',
  PROGRAMA_NACIONAL_CONTROLE_TABAGISMO_2: 'PNCT sessão 2',
  PROGRAMA_NACIONAL_CONTROLE_TABAGISMO_3: 'PNCT sessão 3',
  PROGRAMA_NACIONAL_CONTROLE_TABAGISMO_4: 'PNCT sessão 4',
  SAUDE_AUDITIVA: 'Saúde auditiva',
  SAUDE_OCULAR: 'Saúde ocular',
  VERIFICACAO_DA_SITUACAO_VACINAL: 'Verificação da situação vacinal',
  OUTRAS: 'Outras',
  OUTRO_PROCEDIMENTO_COLETIVO: 'Outro procedimento coletivo',
}

export const publicoAlvoRecord: Record<PublicoAlvoEnum, string> = {
  COMUNIDADE_EM_GERAL: 'Comunidade em geral',
  CRIANCA_0_A_3_ANOS: 'Criança 0 a 3 anos',
  CRIANCA_4_A_5_ANOS: 'Criança 4 a 5 anos',
  CRIANCA_6_A_11_ANOS: 'Criança 6 a 11 anos',
  ADOLESCENTE: 'Adolescente',
  MULHER: 'Mulher',
  GESTANTE: 'Gestante',
  HOMEM: 'Homem',
  FAMILIARES: 'Familiares',
  IDOSO: 'Idoso',
  PESSOAS_COM_DOENCAS_CRONICAS: 'Pessoas com doenças crônicas',
  USUARIO_DE_TABACO: 'Usuário de tabaco',
  USUARIO_DE_ALCOOL: 'Usuário de álcool',
  USUARIO_DE_OUTRAS_DROGAS: 'Usuário de outras drogas',
  PESSOAS_COM_SOFRIMENTO_TRANSTORNO_MENTAL: 'Pessoas com sofrimento ou transtorno mental',
  PROFISSIONAL_DE_EDUCACAO: 'Profissional de educação',
  OUTROS: 'Outros',
}

export interface AtividadeColetivaFormModel {
  lotacaoResponsavel: LotacaoResponsavelFormModel
  dataAtividade: LocalDate
  turno: TurnoEnum
  numeroParticipantes: number
  localAtividade?: LocalAtividadeFieldModel
  tipoAtividade: TipoAtividadeEnum
  temasReuniao?: TemaReuniaoEnum[]
  anotacoes?: string
  lotacoesEnvolvidas: LotacaoEditableTableModel[]
  atividadeEducacaoPse: boolean
  atividadeSaudePse: boolean
  temasSaude?: TemaSaudeEnum[]
  publicoAlvo?: PublicoAlvoEnum[]
  praticasSaude?: PraticaSaudeEnum[]
  procedimento?: ProcedimentoSelectModel
}
